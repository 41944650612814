import { TruncationOptions } from '@/src/types/text';
import * as Sentry from '@sentry/nextjs';
import { isDefined, isObject, isString } from '../lib/utils';

/**
 * Sanitizes the given text for the note by removing excessive spaces and new lines.
 * @param text The text to be sanitized.
 * @returns The sanitized text.
 */
export const noteTextFormat = (text: string) => {
  // Matches one or more consecutive whitespace characters at the beginning of a line that are followed by a newline character
  // and replaces them with an empty string.
  // This essentially removes any empty lines in a row.
  return (
    text
      .replace(/^(?:[\t ]*(?:\r?\n|\r))+/gm, '')
      // then we trim each line (w/ regex) and trim the whole string
      .replace(/^\s+|\s+$/gm, '')
      .trim()
  );
};

const protocolRegex = new RegExp(/^.+?:\/\//);

/**
 * Given a URL, returns the URL withouth the protocol.
 * @param url The URL to be sanitized.
 * @returns The sanitized URL.
 * @example
 * cleanUrl('https://www.google.com?hello=world') // 'google.com'
 * cleanUrl('https://www.google.com?hello=world', { includeSearch: true }) // 'google.com?hello=world'
 * cleanUrl('https://www.google.com') // 'google.com'
 * cleanUrl('www.google.com') // 'google.com'
 * cleanUrl('google.com') // 'google.com'
 */
export const cleanUrl = (
  href: string,
  options?: {
    includeSearch?: boolean;
  },
) => {
  try {
    const url = new URL(href);
    // remove wwww from hostname if present, do not append pathname if it is '/' only
    const fragments = [url.hostname.replace('www.', ''), url.pathname !== '/' ? url.pathname : ''];

    if (options?.includeSearch) {
      fragments.push(url.search);
    }

    return fragments.join('');
  } catch {
    /**
     * probably incomplete href
     * we still return it to ensure things are not breaking but we can only cleanup the protocol and www
     * we can't do much about the rest (search query, hash)
     */
    Sentry.captureMessage('Tried to sanitize invalid href', (scope) =>
      scope.setExtra('href', href),
    );
    return href.replace(protocolRegex, '').replace(/\/$/, '').replace('www.', '');
  }
};

/* Given a string it will add the hyphenation hints to it.
 * This allows it to be wrapped with hyphenation.
 * @param str - The string to add hyphenation hints to.
 * @param n - The number of characters to wait before adding a hyphenation hint.
 * @returns The string with hyphenation hints.
 * @example
 * addHyphenationHints('Hello, world!', 3);
 * // => 'Hel­lo, wor­ld!'
 * // The empty square is a hyphenation hint, not a space.
 */
export function addHyphenationHints(str: string, n: number) {
  let output = '';
  let count = 0;

  for (const char of str) {
    output += char;
    if (/[a-zA-Z]/.test(char)) {
      count++;
      if (count === n) {
        output += '­';
        count = 0;
      }
    } else {
      count = 0;
    }
  }

  return output;
}

const getTextWidth = (text: string, font: string) => {
  // Create a canvas element
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (!context) return 0;

  context.font = font;
  const metrics = context.measureText(text);

  return metrics.width;
};

const calculateTruncationPoints = (
  text: string,
  maxRows: number,
  ellipsis: string,
  availableWidth: number,
  font: string,
  offsetBefore: number,
  keepLastChars: number,
) => {
  let currentRow = 1;
  const start = 0;
  let end = text.length - keepLastChars - ellipsis.length;

  // the total width for the last chars (keepLastChars) and the ellipsis
  const totalLastCharsWidth =
    getTextWidth(text.substring(text.length - keepLastChars), font) + getTextWidth(ellipsis, font);

  let currentWidth = offsetBefore + (currentRow >= maxRows ? totalLastCharsWidth : 0);

  const charSizes: number[] = [];

  for (let i = 0; i < end; i++) {
    const charWidth = getTextWidth(text[i], font);
    charSizes.push(charWidth);

    if (currentWidth + charWidth >= availableWidth) {
      if (currentRow === maxRows) {
        // we make sure to move back taking the width of the chars into account
        let totalWidth = 0;
        let j = i;
        while (totalWidth < totalLastCharsWidth && j >= 0) {
          totalWidth += charSizes[j];
          j--;
        }

        end = Math.max(0, j + 1);
        break;
      }
      currentRow++;
      currentWidth = currentRow >= maxRows ? totalLastCharsWidth : 0;
    }

    currentWidth += charWidth;
  }

  return { start, end };
};

const constructTruncatedText = (
  text: string,
  start: number,
  end: number,
  ellipsis: string,
  keepLastChars: number,
) => {
  const frontPart = text.substring(start, end);
  const lastPart = text.substring(text.length - keepLastChars);
  return `${frontPart}${ellipsis}${lastPart}`;
};

/**
 * Text truncator with multiple options for easier truncation.
 * @param text The text to truncate.
 * @param options The options for truncation.
 * @returns The truncated text.
 */
export const truncateText = (text: string, options: TruncationOptions): string => {
  if (!options.element && !options.targetWidth) return text;

  const { maxRows, ellipsis, offsetBefore = 0, keepLastChars = 0, targetWidth, element } = options;

  // Determine the appropriate width to use
  const actualWidth = element ? element.offsetWidth : targetWidth || 0;
  const font = element
    ? `${window.getComputedStyle(element).fontSize} ${window.getComputedStyle(element).fontFamily}`
    : '16px Arial';

  // Calculate the full text width and compare with max allowed width
  if (getTextWidth(text, font) + offsetBefore <= actualWidth * maxRows) {
    return text; // The whole text fits, no truncation needed
  }

  // Determine truncation points
  const { start, end } = calculateTruncationPoints(
    text,
    maxRows,
    ellipsis,
    actualWidth,
    font,
    offsetBefore,
    keepLastChars,
  );

  // Construct truncated text
  return constructTruncatedText(text, start, end, ellipsis, keepLastChars);
};

/**
 * Converts a capitalized snake case string to a human-readable string.
 * @param text The text to convert.
 * @returns The converted text.
 * @example
 * snakeCaseToWords('HELLO_WORLD') // 'Hello World'
 * snakeCaseToWords('HELLO_WORLD', { capitalize: 'first' }) // 'Hello world'
 */
export const snakeCaseToWords = (
  text: string,
  options?: { capitalize?: 'first' | 'all' },
): string => {
  const words = text
    .split('_')
    .map((word) => word.toLowerCase())
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1));

  if (options?.capitalize === 'first') {
    words[0] = words[0].toLowerCase();
  }

  return words.join(' ');
};

/**
 * Create a simple, small and very quick hash for a given text.
 * @param text The text to hash.
 * @returns The hash.
 */
export const simpleHash = (text: string): number => {
  let hash = 0;
  for (let i = 0; i < text.length; i++) {
    hash = (hash << 5) - hash + text.charCodeAt(i);
    hash |= 0;
  }
  return hash;
};

/**
 * Capitalizes the first letter of a string.
 * @param str The string to capitalize.
 * @returns The capitalized string.
 */
export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Given a boolean returns yes or no.
 * @param bool The boolean to convert.
 * @returns 'Yes' if true, otherwise 'No'.
 */
export const boolToYesOrNo = (bool: boolean): string => {
  return bool ? 'Yes' : 'No';
};

const createIndent = (level: number): string => '  '.repeat(level);

function camelCaseToText(str: string): string {
  const words = str.split(/(?=[A-Z])/).map((word) => word.toLowerCase());
  words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
  return words.join(' ');
}

export const prettifyForLLM = (rootObj: unknown): string => {
  const recursive = (obj: unknown, level: number = 0): string => {
    if (!isDefined(obj)) return '';

    const indent = createIndent(level);
    const stringIndent = createIndent(level + 1);

    if (isString(obj))
      return obj
        .split('\n')
        .map((v, i) => (i === 0 ? v : `${stringIndent}${v}`))
        .join('\n');
    if (typeof obj === 'number' || typeof obj === 'boolean') return `${obj}`;

    if (Array.isArray(obj)) {
      if (obj.length === 0) return '';
      return obj
        .map((item, index) => {
          const itemStr = recursive(item, level + 1);
          return itemStr ? `\n${indent}${index + 1}. ${itemStr}` : '';
        })
        .join('');
    }

    if (isObject(obj)) {
      const entries = Object.entries(obj);
      if (entries.length === 0) return '';
      return entries
        .map(([key, value]) => {
          const valueStr = recursive(value, level + 1);
          return valueStr
            ? `\n${indent}${level > 0 ? '- ' : ''}${camelCaseToText(key)}: ${valueStr}`
            : '';
        })
        .join('');
    }

    return '';
  };

  return trimChars(recursive(rootObj), ['\n']);
};

/**
 * Trims text from a set of chars.
 * @param text the string to trim from.
 * @param trimChars The chars to trim the string off.
 * @returns The trimmed string
 */
export const trimChars = (text: string, chars: string[]): string => {
  let start = 0,
    end = text.length;

  while (start < end && chars.indexOf(text[start]) >= 0) ++start;

  while (end > start && chars.indexOf(text[end - 1]) >= 0) --end;

  return start > 0 || end < text.length ? text.substring(start, end) : text;
};
