'use client';

import { useScrollPosition } from '@/src/hooks/useScrollPosition';
import { mediaMobile } from '@/src/modules/ui/styled-utils';
import { CSS_THEME_COLOR } from '@/src/modules/ui/theme/theme';
import { cssVar } from '@/src/modules/ui/theme/variables';
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import { useInView, UseInViewOptions } from 'framer-motion';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { preventForwardPropsConfig } from '../utils/preventForwardProps';

type ScrollAreaRootProps = {
  grow?: boolean;
  /**
   * fixes some issue when the viewport is bigger and doesn't scroll
   */
  asFlex?: boolean;
};

const ScrollAreaFadeDirection = {
  vertical: css`
    &::after,
    &::before {
      bottom: 0;
      left: 0;
      right: 0;
      height: 1.25rem;
      background: linear-gradient(180deg, var(--scroll-area-gradient-steps));
    }

    &::before {
      bottom: unset;
      top: 0;
      background: linear-gradient(360deg, var(--scroll-area-gradient-steps));
    }

    [data-scroll-vertical='n/a'] & {
      &::before,
      &::after {
        opacity: 0;
      }
    }

    [data-scroll-vertical='top'] & {
      &::before {
        opacity: 0;
      }
    }

    [data-scroll-vertical='bottom'] & {
      &::after {
        opacity: 0;
      }
    }
  `,
  horizontal: css`
    &::after,
    &::before {
      top: 0;
      bottom: 0;
      width: 1.25rem;
      background: linear-gradient(90deg, var(--scroll-area-gradient-steps));
    }
    &::before {
      left: 0;
      right: unset;
      background: linear-gradient(270deg, var(--scroll-area-gradient-steps));
    }

    [data-scroll-horizontal='n/a'] & {
      &::before,
      &::after {
        opacity: 0;
      }
    }

    [data-scroll-horizontal='left'] & {
      &::before {
        opacity: 0;
      }
    }

    [data-scroll-horizontal='right'] & {
      &::after {
        opacity: 0;
      }
    }
  `,
};

interface ScrollAreaFadeProps {
  orientation: keyof typeof ScrollAreaFadeDirection;
  fadeColor?: CSS_THEME_COLOR;
}

const ScrollAreaFade = styled.div<ScrollAreaFadeProps>`
  position: absolute;
  z-index: 1;
  pointer-events: none;
  inset: 0;
  ${({ fadeColor = 'color-bg-secondary' }: { fadeColor?: CSS_THEME_COLOR }) => css`
    --scroll-area-gradient-steps: rgba(${cssVar[`${fadeColor}-rgb`]}, 0) 0%,
      ${cssVar[fadeColor]} 100%;
  `}

  ${({ orientation }) => ScrollAreaFadeDirection[orientation]}

  &::after, &::before {
    content: '';
    position: absolute;

    opacity: 1;
    transition: opacity 0.2s ease-out;
  }
`;

const ScrollAreaRoot = styled(ScrollAreaPrimitive.Root).withConfig(
  preventForwardPropsConfig(['grow']),
)<ScrollAreaRootProps>`
  position: relative;
  overflow: hidden;

  --scrollbar-size: 10px;
  --scrollbar-bg-color: ${cssVar['color-bg-tertiary']};
  --scrollbar-thumb-color: hsla(${cssVar['color-bg-octonary-hsl']}, 0.4);

  ${({ asFlex }) =>
    asFlex &&
    css`
      display: flex;
      flex-direction: column;
    `};

  ${({ grow }) => grow && 'flex: 1'};
`;

type ScrollAreaViewportProps = {
  expandVertically?: boolean;
  /**
   * default radix is display table. This will force the inner div to display block
   * Table grows horizontally as well, but sometimes we don't want that
   */
  innerDisplayFlex?: boolean;
  // e.g. when we want to display empty states in center
};

export const useInViewWithinRadixScrollArea = (
  ref: React.RefObject<Element>,
  options?: UseInViewOptions,
) => {
  const radixViewport = ref.current?.closest('[data-radix-scroll-area-viewport]') || null;
  const refEl = React.useRef(radixViewport);
  refEl.current = radixViewport;

  const inView = useInView(ref, {
    ...options,
    root: options?.root || refEl,
  });

  return inView;
};

const ScrollAreaViewport = styled(ScrollAreaPrimitive.Viewport).withConfig(
  preventForwardPropsConfig(['expandVertically', 'innerDisplayFlex']),
)<ScrollAreaViewportProps>`
  width: 100%;
  height: 100%;
  border-radius: inherit;

  ${({ expandVertically }) =>
    expandVertically &&
    css`
      // Force the table div from Radix to occupy the full height of the viewport
      > div {
        min-height: 100%;
        height: 100%;
      }
    `};
  ${({ innerDisplayFlex }) =>
    innerDisplayFlex &&
    css`
      display: block;
      & > div {
        min-height: 100%;
        display: flex !important;
        flex-direction: column;
      }
    `}
`;

const ScrollAreaCorner = ScrollAreaPrimitive.Corner;

type ScrollAreaScrollbarProps = {
  variant?: 'default' | 'subtle' | 'none';
};

const scrollbarVariantsCss = ({ variant = 'default' }: ScrollAreaScrollbarProps) => {
  switch (variant) {
    case 'subtle':
      return css`
        --scrollbar-bg-color: transparent;
        --scrollbar-thumb-color: hsla(${cssVar['color-bg-octonary-hsl']}, 0.2);
      `;
    case 'none':
      return css`
        display: none;
      `;
    default:
      return '';
  }
};

const ScrollAreaScrollbar = styled(
  ScrollAreaPrimitive.ScrollAreaScrollbar,
)<ScrollAreaScrollbarProps>`
  display: flex;
  touch-action: none;
  user-select: none;
  background-color: var(--scrollbar-bg-color);

  ${({ orientation }) =>
    orientation === 'vertical' &&
    `
    width: var(--scrollbar-size);
    height: 100%;
    border-left: 1px solid transparent;
    padding: 2px;
  `}
  ${({ orientation }) =>
    orientation === 'horizontal' &&
    `
    height: var(--scrollbar-size);
    flex-direction: column;
    border-top: 1px solid transparent;
    padding: 2px;
  `}

  ${scrollbarVariantsCss}

  // scrollbar track and thumb hide on touch screens
  @media (pointer:coarse), ${mediaMobile} {
    --scrollbar-bg-color: transparent;
  }
`;

const ScrollAreaThumb = styled(ScrollAreaPrimitive.ScrollAreaThumb)`
  position: relative;
  flex: 1;
  border-radius: 9999px;
  background-color: var(--scrollbar-thumb-color);
`;

type ScrollAreaProps = ScrollAreaViewportProps & {
  orientation?: 'vertical' | 'horizontal' | 'both';
  scrollbarVariant?: 'default' | 'subtle' | 'none';
  fade?: boolean;
  fadeColor?: CSS_THEME_COLOR;
};

const ScrollArea = React.forwardRef<
  React.ElementRef<typeof ScrollAreaRoot>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaRoot> & ScrollAreaProps
>(
  (
    {
      children,
      orientation = 'vertical',
      expandVertically,
      innerDisplayFlex,
      scrollbarVariant,
      fade,
      fadeColor,
      ...props
    },
    ref,
  ) => {
    const viewportRef = React.useRef<HTMLDivElement | null>(null);
    const [scrollPosition] = useScrollPosition(viewportRef.current);

    return (
      <ScrollAreaRoot
        ref={ref}
        {...props}
        data-scroll-vertical={scrollPosition?.vertical}
        data-scroll-horizontal={scrollPosition?.horizontal}
      >
        <ScrollAreaViewport
          expandVertically={expandVertically}
          innerDisplayFlex={innerDisplayFlex}
          ref={viewportRef}
        >
          {children}
        </ScrollAreaViewport>

        {orientation === 'vertical' || orientation === 'both' ? (
          <>
            <ScrollAreaScrollbar orientation="vertical" variant={scrollbarVariant}>
              <ScrollAreaThumb />
            </ScrollAreaScrollbar>
            {fade && <ScrollAreaFade orientation="vertical" fadeColor={fadeColor} />}
          </>
        ) : null}
        {orientation === 'horizontal' || orientation === 'both' ? (
          <>
            <ScrollAreaScrollbar orientation="horizontal" variant={scrollbarVariant}>
              <ScrollAreaThumb />
            </ScrollAreaScrollbar>
            {fade && <ScrollAreaFade orientation="horizontal" fadeColor={fadeColor} />}
          </>
        ) : null}

        <ScrollAreaCorner />
      </ScrollAreaRoot>
    );
  },
);

ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

export default Object.assign(ScrollArea, {
  Root: ScrollAreaRoot,
  Viewport: ScrollAreaViewport,
  Corner: ScrollAreaCorner,

  Bar: ScrollAreaScrollbar,
  Thumb: ScrollAreaThumb,
});
